const dataSource =
    "https://docs.google.com/spreadsheets/d/e/" +
    "2PACX-1vSXyo2ZiC3OpBZl8aKxK_bT4SnZm48DTlAzHDBuFmfxEGHz7NslVp6EZRMhyIgZicfGDy1_WXMcXidF" +
    "/pub?gid=2025548621&single=true&output=csv";

import dayjs from 'dayjs';
const referenceDate = dayjs("2018-05-10");

const reloadTime = 173 * 1000;

var lastLoaded = dayjs();
const loaded = dayjs();

var updateField;

function formatDay(d) {
    return d.format("ddd");
}

function formatDate(d = null) {
    if (!d) {
        d = dayjs();
    } else {
        d = dayjs(d);
    }
    return d.format("DD.MM.YYYY HH:mm:ss");
}

function calculateDutyKids(date, names) {
    const days = date.diff(referenceDate, "days");
    const count = names.length;
    // first name, offset 2 for compatibility with sheets, wrap around
    const index1 = ((days % count) + 2) % count;
    // other name, wrap around if needed
    const index2 = (index1 + 1 ) % count;
    return [names[index1], names[index2]];
}

function updateContent(content, loadDate) {
    // content is like name1\r\nname2\r\n...
    console.log(content);
    const names = content.match(/\p{Alpha}+/gu); // match letters in any language
    console.log("Got names: " + names);
    const today = dayjs().hour(0).minute(0).second(0); // we use 00:00:00 to ensure consistent date calculations
    const tomorrow = today.add(1, "d");

    const [day1name1, day1name2] = calculateDutyKids(today, names)
    const [day2name1, day2name2] = calculateDutyKids(tomorrow, names)


    document.getElementById("day1").innerHTML = formatDay(today);
    document.getElementById("day2").innerHTML = formatDay(tomorrow);
    document.getElementById("day1name1").innerHTML = day1name1;
    document.getElementById("day1name2").innerHTML = day1name2;
    document.getElementById("day2name1").innerHTML = day2name1;
    document.getElementById("day2name2").innerHTML = day2name2;

    updateField.innerHTML = "✅ " + formatDate(loadDate);

    lastLoaded = loadDate;

    return;
}

async function update() {
    let url = new URL(dataSource);
    const loadDate = dayjs();
    updateField.innerHTML = `🟠 ${formatDate(loadDate)}`;
    return fetch(url)
        .then((res) => {
            if (res.ok) {
                return res.text();
            }
            throw Error(res);
        })
        .then(content => updateContent(content, loadDate))
        .catch((err) => {
            updateField.innerHTML =
                `❌ ${formatDate(loadDate)} (${formatDate(lastLoaded)})`;
            console.error(err);
        })
}

document.addEventListener("DOMContentLoaded", () => {
    setInterval(update, reloadTime);
    updateField = document.getElementById("update")
    updateField.addEventListener("click", update);
    document.getElementById("loaded").innerHTML = formatDate(new Date());
    return update();
    // document.getElementById("version").innerHTML = version;
});